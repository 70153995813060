import { React, useEffect, useState } from "react";
import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap";
import { useLocation } from 'react-router-dom';

const Header = () => {
  const location = useLocation();
  const [authenticated, setauthenticated] = useState(null);
  useEffect(() => {
    const loggedInUser = localStorage.getItem("authenticated");
    if (loggedInUser) {
      setauthenticated(loggedInUser);
    }
  }, []);

  var link = <NavDropdown.Item href="/">Login</NavDropdown.Item>;
  if (authenticated) {
    link = 
    <>
      <NavDropdown.Item href="/profile">Profile</NavDropdown.Item>
      <NavDropdown.Item href="/logout">Logout</NavDropdown.Item>
      {/*<NavDropdown.Item href="/users">Users</NavDropdown.Item>*/}
    </>;
  }

  if (location.pathname === '/voice') {
    return (<> </>);
  }

  return (
    <>
    <Navbar bg="dark" variant="dark">
      <Container >
        <Navbar.Brand href="/">
            <img
              alt=""
              src={process.env.PUBLIC_URL + '/favicon.ico'}
              width="30"
              height="30"
              className="d-inline-block align-top"
            />{' '}
            Bot Vasiliy
          </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <NavDropdown title="Menu" expand="false" id="basic-nav-dropdown">
              {link}
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    </>
  );
};

export default Header;