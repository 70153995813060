import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";

import apiUrls from "../store/api.const";

const UserContext = React.createContext({
  user: {}, fetchUsers: () => {}
})

export default function Users() {
  const [cookies, setCookie] = useCookies();
  const [user, setUser] = useState({})
  const fetchUsers = async () => {
    const response = await fetch(apiUrls.users, {
      headers: {
        Authorization: 'Token ' + cookies.token
      }
    })
    const users = await response.json()
    setUser(users.user)
  }
  useEffect(() => {
    fetchUsers()
  }, [])
  return (
    <>
    <UserContext.Provider value={{user, fetchUsers}}>
      <h3>{user.username}</h3>
      <h3>{user.email}</h3>
    </UserContext.Provider>
    </>
  )
}