import './App.css';
import { Routes, Route } from "react-router-dom";

import Header from "./components/Header";
import Home from './components/Home';
import Login from './components/Login';
import Users from "./components/Users";
import TwitchConnect from './components/TwitchConnect';
import Register from './components/Register';
import Logout from './components/Logout';
import Settings from './components/Settings';
import Voice from "./components/Voice";

function App() {
  return (
    <>
    <Header/>
      <Routes>
        <Route exact path='/' element={<Home />}/>
        <Route exact path='/login' element={<Login />}/>
        <Route exact path='/logout' element={<Logout />}/>
        <Route exact path='/register' element={<Register />}/>
        <Route exact path='/users' element={<Users />}/>
        <Route exact path='/twitch' element={<TwitchConnect />}/>
        <Route exact path='/settings' element={<Settings />}/>
        <Route exact path='/voice' element={<Voice />}/>
      </Routes>
    </>
  );
}

export default App;
