const clienId = 'cj6u5ko0kn9bpdgajxrbz5ircahgff'
// process.env.CLIENT_ID
const domain = 'botvasiliy.pro'
const baseApiUrl = 'https://' + domain + '/api/v1/'
// const baseApiUrl = 'http://localhost:8000/api/v1/'

const wsUrl = 'wss://' + domain + '/ws/'
const connectTwitchUrl = 'https://id.twitch.tv/oauth2/authorize?client_id=' + clienId + '&redirect_uri='+ 'https://' + domain + '/twitch&response_type=code&scope=user:edit+user:read:email+chat:edit+chat:read+channel:moderate+user:edit:broadcast&force_verify=true'

const apiUrls = {
  login: baseApiUrl + 'auth/login/',
  register: baseApiUrl + 'auth/register/',
  refreshToken: baseApiUrl + 'auth/refresh_token',
  users: baseApiUrl + 'users/',
  settings: baseApiUrl + 'settings/',
  customCommands: baseApiUrl + 'custom_commands/',
  notices: baseApiUrl + 'notices/',
  connectToTwitch: baseApiUrl + 'twitch/connect_to_twitch'
};

export default apiUrls;
export {
  wsUrl,
  connectTwitchUrl
};
