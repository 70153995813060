import { React, useEffect }from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import {Stack, Form, Button, FormGroup, Checkbox, Row, Container, ToggleButton, Col} from "react-bootstrap";

const Logout = () => {
    const navigate = useNavigate();
    const [cookies, setCookie] = useCookies();
    setCookie('token', null);
    localStorage.removeItem("authenticated");
    useEffect(() => {
        navigate("/");
      }, []);
};

export default Logout;