import React, { useEffect, useState } from "react";
import { useNavigate, Link, Route } from "react-router-dom";
import { useCookies } from "react-cookie";
import apiUrls from "../store/api.const";
import { Form, Button, Container, Row } from "react-bootstrap";

const Register = () => {
    const navigate = useNavigate();
    const routeChange = () =>{ 
      let path = `/`; 
      navigate(path);
    }
    const [cookies, setCookie] = useCookies();
    const [token, setToken] = useState("");
    const [username, setusername] = useState("");
    const [password1, setpassword1] = useState("");
    const [password2, setpassword2] = useState("");
    const [email, setemail] = useState("");
    const [authenticated, setauthenticated] = useState(localStorage.getItem(localStorage.getItem("authenticated")|| false));
    const users = [{ username: "Jane", password: "testpassword" }];
    const handleSubmit = (e) => {
      e.preventDefault()
      if (password1 === password2) {
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({user: {username: username, email: email, password: password1}})
        };
        fetch(apiUrls.register, requestOptions)
            .then(response => response.json())
            .then(data => {
                setToken(data.user.token)
                setCookie('token', data.user.token)
                localStorage.setItem("authenticated", true);
                navigate('/');
            });
        }
        else {
          alert('passwords muse be same')
        }
    };
    return (
      <Container className="text-center mt-3">
        <h2>Sign up to Botvasiliy</h2>
        <Form className="text-center m-auto w-50" onSubmit={handleSubmit}>
        <Form.Group className="col-12 mb-3" controlId="formBasicEmail">
          <Form.Control 
            className="text-center"
            type="email"
            placeholder="Email"
            name="Email"
            value={email}
            onChange={(e) => setemail(e.target.value)}
          />
        </Form.Group>

        <Form.Group className="col-12 mb-3" controlId="formBasicEmail">
          <Form.Control 
            className="text-center"
            type="text"
            placeholder="Username"
            name="Username"
            value={username}
            onChange={(e) => setusername(e.target.value)}
          />
        </Form.Group>

        <Form.Group className="col-12 mb-3" controlId="formBasicPassword">
          <Form.Control
            className="text-center"
            placeholder="Password"
            type="password"
            name="Password"
            value={password1}
            onChange={(e) => setpassword1(e.target.value)}
          />
          <Form.Text id="passwordHelpBlock" muted>
            Your password must be 8-20 characters long, contain letters and numbers,
            and must not contain spaces, special characters, or emoji.
          </Form.Text>
        </Form.Group>
        <Form.Group className="col-12 mb-3" controlId="formBasicPassword">
          <Form.Control
            className="text-center"
            placeholder="Repeat your password"
            type="password"
            name="Password"
            value={password2}
            onChange={(e) => setpassword2(e.target.value)}
          />
        </Form.Group>
        <div class="col-12">
        <Button className="btn btn-light mb-4 mx-1" variant="primary" type="submit">
          Sign up
        </Button>
        <Button className="btn btn-light mb-4 mx-1" variant="primary" onClick={routeChange}>
          Back
        </Button>
        </div>
      </Form>
    </Container>
    )
  };

export default Register;