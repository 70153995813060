import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import apiUrls from "../store/api.const";
import Cookies from 'universal-cookie';
import { TagsInput } from "react-tag-input-component";
import {
  Form,
  Button,
  FloatingLabel,
  Container,
  ToggleButton,
  Spinner,
  Card,
  Row,
  InputGroup,
} from "react-bootstrap";
// import TimeKeeper from "react-timekeeper";

const Settings = () => {
  const cookies = new Cookies();

  const navigate = useNavigate();
  const [authenticated, setauthenticated] = useState(localStorage.getItem(localStorage.getItem("authenticated")|| false));
  const loggedInUser = localStorage.getItem("authenticated");
  useEffect(() => {
    if (loggedInUser) {
        setauthenticated(loggedInUser);
    }
    else {
        navigate('/login');
    }
  }, []);
  const [newCustomCommand, setNewCustomCommand] = useState({name: "", reply: ""});
  const [newNotice, setNewNotice] = useState({text: "", interval: 0});

  const [settings, setSettings] = useState({});
  const [notices, setNotices] = useState([]);
  const [customCommands, setCustomCommands] = useState([]);
  const fetchSettings = async () => {
    const response = await fetch(apiUrls.settings, {
    headers:{'Content-Type':'application/json'},
    })
    if (response.status === 200) {
        const settings = await response.json();
        setSettings(settings);
    }
    else {setSettings({})}
  }
  function saveNewCustomCommand(newCustomCommand) {
    fetch(apiUrls.customCommands, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(newCustomCommand)
    }).then((response) => {
      fetchCustomComamnds();
    })
  }
  const fetchCustomComamnds = async () => {
    const response = await fetch(apiUrls.customCommands, {
    headers: {
        Authorization: 'Token ' + cookies.get('token')
    }
    })
    if (response.status === 200) {
        const resp = await response.json();
        setCustomCommands([...resp.custom_commands]);
    }
    else {setCustomCommands([])}
  }

  const fetchNotices = async () => {
    const response = await fetch(apiUrls.notices, {
    headers: {
        Authorization: 'Token ' + cookies.get('token')
    }
    })
    if (response.status === 200) {
        const resp = await response.json();
        setNotices(resp.notices);
    }
    else {setNotices([])}
  }

  function saveNewNotice(newNotice) {
    fetch(apiUrls.notices, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(newNotice)
    }).then((response) => {
      fetchNotices();
    })
  }

  useEffect(() => {
    fetchSettings();
    fetchCustomComamnds();
    fetchNotices();
  }, []);

  const handleSave = async (e) => {
    const response = fetch(
      apiUrls.settings + settings.id,
      {
          method: 'PUT',
          headers: {'Content-Type':'application/json'},
          body: JSON.stringify({
              default_commands: settings.default_commands,
              antispam: settings.antispam,
              banned_words: settings.banned_words,
              follow_notification: settings.follow_notification,
              follow_notification_text: settings.follow_notification_text,
              bot_state: settings.bot_state ? 1 : 0,
          }),
      }
    );
    if (response.status === 200) {
        const settings = await response.json();
        setSettings(settings);
    }
  };

  function addCustomCommand(e) {
    if (newCustomCommand.name === "" || newCustomCommand.reply === "") {
      return;
    }
    saveNewCustomCommand(newCustomCommand);
    e.target.parentNode.children[1].value = '';
    e.target.parentNode.children[2].value = '';
    setNewCustomCommand({name: "", reply: ""});
  }

  function addNotice(e) {
    if (newNotice.text === "" || parseInt(newNotice.interval) === 0) {
      return;
    }
    e.target.parentNode.children[0].value = '';
    e.target.parentNode.children[1].value = '';
    saveNewNotice(newNotice);
    setNewNotice({text: "", interval: ""});
  }

  function deleteNotice(e) {
  fetch(apiUrls.notices + e.target.value, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((response) => {
    fetchNotices();
  })
  }

function updateNotice(e) {
  let notice = notices.find((el) => el.id === parseInt(e.target.value));
  fetch(apiUrls.notices + e.target.value, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      text: notice.text,
      interval: notice.interval,
    })
  }).then((response) => {
    fetchNotices();
  })
}

  const deleteCustomCommand = (e) => {
    fetch(apiUrls.customCommands + e.target.value, {
      method: 'DELETE',
      // body: JSON.stringify(newNotice)
    }).then((response) => {
      fetchCustomComamnds();
    })
  }

  const updateCustomCommand = (e) => {
    let customCommand = customCommands.find((el) => el.id === parseInt(e.target.value));
    fetch(apiUrls.customCommands + e.target.value, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: customCommand.name,
        reply: customCommand.reply,
        state: customCommand.state,
        flags: customCommand.flags,
      })
    }).then((response) => {
      fetchCustomComamnds();
    })
  }

  const changeCommandState = (e, el) => {
    el.state = el.state ? 0 : 1;
    const response = fetch(
      apiUrls.customCommands + el.id,
      {
          method: 'PUT',
          headers: {'Content-Type':'application/json'},
          body: JSON.stringify({
            name: el.name,
            reply: el.reply,
            state: el.state,
            flags: el.flags,
          }),
      }
    ).then((response) => {
        fetchCustomComamnds();
    });
  };

  const getDefaultCommandDescripton = (name) => {
    let desc = {
      "followage": "The time since the user followed the channel",
      "uptime": "The time since the stream started",
      "game": "The current game",
      "title": "The stream title",
      "voice": "The bot will say the given text",
    }
    return desc[name];
  }

  const getSeconds = (t) => {
    return parseInt(parseInt(t) % 60);
  }

  const getMinutes = (t) => {
    return parseInt(parseInt(t) / 60 % 60);
  }

  const getHours = (t) => {
    return parseInt(parseInt(t) / 3600);
  }

  const changeDefaultCommandState = (e, el) => {
    el.enable = el.enable ? false : true;
    let newSettings = Object.assign({}, settings);
    setSettings(newSettings);
  }

  return (
    <>
    <Container className="settings-container mt-3" >
        { settings && Object.keys(settings).length === 0 ? (
            <>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
            </>
        ) : (
            <>
              <Row className="row m-auto mt-3 mb-3">
                <Button
                  variant="dark"
                  type="submit"
                  onClick={(e) => {
                    window.open('/voice?code=' + settings.user_uuid)
                  }}
                >
                  Open voice widget
                </Button>
              </Row>
              <Row className="row m-auto mt-3 mb-3">
                <h2>
                  <InputGroup className="mb-3">
                    <Form.Check
                        autoComplete="off"
                        className="pl-3"
                        defaultChecked={settings.bot_state}
                        type='switch'
                        id='checkbox-bot-state'
                        label='Bot Status'
                        onChange={(e) => {
                          settings.bot_state = e.target.checked;
                          let newSettings = Object.assign({}, settings);
                          setSettings(newSettings);
                        }}
                    />
                  </InputGroup>
                </h2>
              </Row>
              <Container id="setings" className="row mt-3 mb-3">
                <Container className="row m-auto mt-3 mb-3">
                  <h2>Default commands</h2>
                  { settings.default_commands.map((el) => {
                    return (
                      <>
                        <Card style={{ width: '16rem', marginLeft: '2px', marginRight: '2px'}} className="col">
                          <Card.Body>
                            <InputGroup>
                              <Form.Check
                                autoComplete="off"
                                className="pl-3"
                                checked={!!el.enable}
                                type='switch'
                                id={el.name}
                                // label={el.enable ? "On" : "Off"}
                                onChange={(e) => {
                                  changeDefaultCommandState(e, el);
                                }}
                              />
                              <Card.Title>{el.name}</Card.Title>
                            </InputGroup>
                            <Card.Text>
                              {getDefaultCommandDescripton(el.name)}
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </>
                    )}
                  )}
                </Container>
                <Container className="antispam-settings row m-auto mt-3 mb-3">
                  <InputGroup>
                    <h3>
                      <Form.Check
                        autoComplete="off"
                        className="pl-3"
                        checked={!!settings.antispam.enable}
                        type='switch'
                        id='checkbox-bot-state'
                        label={settings.antispam.enable ? "Antispam On" : "Antispam Off"}
                        onChange={(e) => {
                          settings.antispam.enable = e.target.checked;
                          let newSettings = Object.assign({}, settings);
                          setSettings(newSettings);
                        }}
                      />
                    </h3>
                    <Container className="antispam-settings row m-auto mt-3 mb-3">
                    { settings.antispam.options.map((el) => {
                       return (
                         <>
                           <Form.Check
                            autoComplete="off"
                            className="pl-3"
                            checked={!!el.enable}
                            type='switch'
                            size="sm"
                            id='checkbox-antispam-option-state'
                            label={el.name}
                            onChange={(e) => {
                              el.enable = e.target.checked;
                              let newSettings = Object.assign({}, settings);
                              setSettings(newSettings);
                            }}
                          />
                         </>
                       )
                    })}
                    </Container>
                  </InputGroup>
                </Container>
                <Container className="row m-auto mt-3 mb-3">
                  <h2>Banned words</h2>
                  <TagsInput
                      value={settings.banned_words}
                      onChange={(value) => {
                        console.log(value);
                        settings.banned_words = value;
                        let newSettings = Object.assign({}, settings);
                        setSettings(newSettings)
                      }}
                      name="list-of-banned-words"
                      placeHolder="enter banned words"
                  />
                </Container>
                <Container className="row m-auto mt-3 mb-3">
                  <h3>
                    <Form.Check
                      autoComplete="off"
                      className="pl-3"
                      checked={!!settings.follow_notification}
                      type='switch'
                      id='checkbox-bot-state'
                      label='Follow notification'
                      onChange={(e) => {
                        settings.follow_notification = e.target.checked;
                        let newSettings = Object.assign({}, settings);
                        setSettings(newSettings);
                      }}
                    />
                  </h3>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Text for follow notification"
                    className="mb-3"
                  >
                    <Form.Control
                      as="textarea"
                      rows={3}
                      className="col-sm p-19 rounded"
                      placeholder="Text for follow notification"
                      defaultValue={settings.follow_notification_text}
                      onChange={(e) => {settings.follow_notification_text = e.target.value; setSettings(settings);}}
                    />
                  </FloatingLabel>
                </Container>
                <Container className="row m-auto mt-3 mb-3">
                  <Button className="btn mb-4 mx-1" variant="dark" type="submit" onClick={handleSave}>
                    Save Settings
                  </Button>
                </Container>
                <Container className="row m-auto mt-3 mb-3 rounded">
                  <h2>Notices</h2>
                  <p>Notices are the messages that will be sent to the chat with given interval</p>
                  <div className="input-group mb-3">
                    <Form.Control
                      type="text"
                      className="col-sm"
                      placeholder="Notice text"
                      style={{width: "53%"}}
                      defaultValue={newNotice.text}
                      onChange={(e) => {newNotice.text = e.target.value; e.preventDefault(); setNewNotice(newNotice); }}
                    />
                    <Form.Control
                      type="number"
                      className="col-sm"
                      style={{width: "5%"}}
                      placeholder="Hours"
                      value={getHours(newNotice.interval)}
                      onChange={(e) => {
                        let v = e.target.value && parseInt(e.target.value) > 0 ? e.target.value : 0;
                        newNotice.interval = (newNotice.interval - getHours(newNotice.interval) * 3600) + parseInt(v) * 3600;
                        let n = Object.assign({}, newNotice);
                        setNewNotice(n);
                      }}
                    />
                    <span
                      className="input-group-text"
                      style={{width: "4%"}}
                    >
                      H
                    </span>
                    <Form.Control
                        type="number"
                        className="col-sm"
                        style={{width: "5%"}}
                        placeholder="Minutes"
                        value={getMinutes(newNotice.interval)}
                        onChange={(e) => {
                          let v = e.target.value && parseInt(e.target.value) > 0 ? e.target.value : 0;
                          newNotice.interval = (newNotice.interval - getMinutes(newNotice.interval) * 60) + parseInt(v) * 60;
                          let n = Object.assign({}, newNotice);
                          setNewNotice(n);
                        }}
                    />
                    <span
                        className="input-group-text"
                        style={{width: "4%"}}
                    >
                      M
                    </span>
                    <Form.Control
                        type="number"
                        className="col-sm"
                        style={{width: "5%"}}
                        placeholder="Seconds"
                        value={getSeconds(newNotice.interval)}
                        onChange={(e) => {
                          let v = e.target.value && (parseInt(e.target.value) > 0 || newNotice.interval > 0) ? e.target.value : 1;
                          newNotice.interval = (newNotice.interval - getSeconds(newNotice.interval)) + getSeconds(v);
                          let n = Object.assign({}, newNotice);
                          setNewNotice(n);
                        }}
                    />
                    <span
                        className="input-group-text"
                        style={{width: "4%"}}
                    >
                      S
              </span>
                    <Button
                      className="btn btn-light"
                      variant="dark"
                      onClick={addNotice}
                      style={{width: "20%"}}
                    >
                      Add notice
                    </Button>
                  </div>

                  { notices.map((notice) => {
                    return (
                      <>
                        <div className="input-group mb-3">
                          <Form.Control
                              type="text"
                              // className="col-sm"
                              placeholder="Notice text"
                              defaultValue={notice.text}
                              style={{width: "53%"}}
                              onChange={(e) => {
                                notice.text = e.target.value;
                                setNotices([...notices]);
                              }}
                          />
                          <Form.Control
                              type="number"
                              className="col-sm"
                              style={{width: "5%"}}
                              placeholder="Hours"
                              value={getHours(notice.interval)}
                              onChange={(e) => {
                                let v = e.target.value && parseInt(e.target.value) > 0 ? e.target.value : 0;
                                notice.interval = (notice.interval - getHours(notice.interval) * 3600) + parseInt(v) * 3600;
                                setNotices([...notices]);
                              }}
                          />
                          <span
                            className="input-group-text"
                            style={{width: "4%"}}
                          >
                            H
                          </span>
                          <Form.Control
                              type="number"
                              className="col-sm"
                              style={{width: "5%"}}
                              placeholder="Minutes"
                              value={getMinutes(notice.interval)}
                              onChange={(e) => {
                                let v = e.target.value && parseInt(e.target.value) > 0 ? e.target.value : 0;
                                notice.interval = (notice.interval - getMinutes(notice.interval) * 60) + parseInt(v) * 60;
                                setNotices([...notices]);
                              }}
                          />
                          <span
                              className="input-group-text"
                              style={{width: "4%"}}
                          >
                            M
                          </span>
                          <Form.Control
                              type="number"
                              className="col-sm"
                              style={{width: "5%"}}
                              placeholder="Seconds"
                              value={getSeconds(notice.interval)}
                              onChange={(e) => {
                                let v = e.target.value && (parseInt(e.target.value) > 0 || notice.interval > 0) ? e.target.value : 1;
                                notice.interval = (notice.interval - getSeconds(notice.interval)) + getSeconds(v);
                                setNotices([...notices]);
                              }}
                          />
                          <span
                              className="input-group-text"
                              style={{width: "4%"}}
                          >
                            S
                          </span>
                          <Button
                              className="btn btn-danger"
                              variant="dark"
                              style={{width: "10%"}}
                              onClick={updateNotice}
                              value={notice.id}
                          >
                            Update
                          </Button>
                          <Button
                              className="btn btn-danger"
                              variant="danger"
                              style={{width: "10%"}}
                              onClick={deleteNotice}
                              value={notice.id}
                          >
                            Delete
                          </Button>
                        </div>
                      </>
                    )
                      }
                  )}
                </Container>
                <Container className="row m-auto mt-3 mb-3 rounded">
                <h2>Custom commands</h2>
                  <p>Custom command is a simple command with given reply</p>
                  <div className="input-group mb-3">
                    <span
                      className="input-group-text"
                      style={{width: "2%"}}
                    >
                      !
                    </span>
                    <Form.Control
                      type="text"
                      className="col-sm"
                      placeholder="Custom command name"
                      style={{width: "18%"}}
                      defaultValue={newCustomCommand.name}
                      onChange={(e) => {newCustomCommand.name = e.target.value.replace(" ", "_"); setNewCustomCommand(newCustomCommand); }}
                    />
                    <Form.Control
                      type="text"
                      className="col-sm"
                      placeholder="Custom command reply..."
                      style={{width: "60%"}}
                      defaultValue={newCustomCommand.reply}
                      onChange={(e) => {newCustomCommand.reply = e.target.value; setNewCustomCommand(newCustomCommand);}}
                    />
                    <Button
                      className="btn btn-light"
                      variant="dark"
                      onClick={addCustomCommand}
                      style={{width: "20%"}}
                    >
                      Add command
                    </Button>
                  </div>
                  { customCommands.map((cc) => {
                    return (
                      <>
                        <div className="input-group mb-3">
                          <span
                            className="input-group-text"
                            style={{width: "2%"}}
                          >
                            !
                          </span>
                          <Form.Control
                            type="text"
                            className="col-sm"
                            placeholder="Custom command name"
                            style={{width: "18%"}}
                            value={cc.name}
                            onChange={(e) => {
                              cc.name = e.target.value.replace(" ", "_");
                              setCustomCommands([...customCommands]);
                            }}
                          />
                          <Form.Control
                            type="text"
                            className="col-sm"
                            style={{width: "60%"}}
                            placeholder="Custom command reply..."
                            value={cc.reply}
                            onChange={(e) => {
                              cc.reply = e.target.value;
                              setCustomCommands([...customCommands]);
                            }}
                          />
                          <ToggleButton
                            id={cc.id}
                            type="checkbox"
                            variant="outline-dark"
                            style={{width: "7%"}}
                            checked={!!cc.state}
                            onChange={(e) => {
                              // cc.state = e.target.value; setCustomCommands(customCommands);
                              changeCommandState(e ,cc);
                            }}
                          >
                            {cc.state ? "Enable" : "Disable"}
                          </ToggleButton>
                          <Button
                            className="btn btn-danger"
                            variant="dark"
                            style={{width: "7%"}}
                            onClick={updateCustomCommand}
                            value={cc.id}
                          >
                            Update
                          </Button>
                          <Button
                            // className="btn btn-secondary"
                            variant="danger"
                            style={{width: "6%"}}
                            onClick={deleteCustomCommand}
                            value={cc.id}
                          >
                            Delete
                          </Button>
                        </div>
                      </>
                    )}
                  )}
                    </Container>
                </Container>
            </>
            )
        }
    </Container>
    </>
    )
};

export default Settings;