import { React, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import apiUrls from "../store/api.const";
import { useCookies } from "react-cookie";

import { Form, Button, Container, Row } from "react-bootstrap";

const Login = () => {
    const navigate = useNavigate();
    const routeChange = () =>{ 
        let path = `/`; 
        navigate(path);
      }
    const [email, setemail] = useState("");
    const [password, setpassword] = useState("");
    const [cookies, setCookie] = useCookies();
    const [token, setToken] = useState("");
    const [authenticated, setauthenticated] = useState(localStorage.getItem(localStorage.getItem("authenticated")|| false));
    const handleSubmit = (e) => {
        e.preventDefault()
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({user: { email: email, password: password}})
        };
        fetch(apiUrls.login, requestOptions)
            .then(response => response.json())
            .then(data => {
                setToken(data.user.token)
                setCookie('token', data.user.token)
                localStorage.setItem("authenticated", true);
                navigate('/');
            });
    }
    return (
      <>
      <Container className="text-center mt-3">
        <h2>Sign in to Botvasiliy</h2>
        <Form className="text-center m-auto w-50" onSubmit={handleSubmit}>
        <Form.Group className="col-12 mb-3" controlId="formBasicEmail">
          <Form.Control 
            className="text-center"
            type="email"
            placeholder="Email"
            name="Email"
            value={email}
            onChange={(e) => setemail(e.target.value)}
          />
        </Form.Group>

        <Form.Group className="col-12 mb-3" controlId="formBasicPassword">
          <Form.Control
            className="text-center"
            placeholder="Password"
            type="password"
            name="Password"
            value={password}
            onChange={(e) => setpassword(e.target.value)}
          />
        </Form.Group>
        <div class="col-12">
        <Button className="btn btn-light mb-4 mx-1" variant="primary" type="submit">
          Sign in
        </Button>
        <Button className="btn btn-light mb-4 mx-1" variant="primary" onClick={routeChange}>
          Back
        </Button>
        </div>
      </Form>
      <p>New to botvasiliy? <Link to='/register'>Sign up</Link></p>
    </Container>
      </>
    )
  };

  export default Login;