import React, {useEffect,useState} from "react";
import {Link, useLocation} from "react-router-dom";
import useWebSocket, { ReadyState } from "react-use-websocket"
import { CSSTransition } from 'react-transition-group';
import { Container } from "react-bootstrap";
import { wsUrl } from "../store/api.const";


function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Voice = () => {
  let query = useQuery();
  let code = query.get("code");
  const [message, setMessage] = useState({"user": "test","text": "from code", "url": "", "gifUrl": ""});
  const [showMessage, setShowMessage] = useState(false);
  const WS_URL = wsUrl + code;
  const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocket(
    WS_URL,
    {
      share: false,
      shouldReconnect: () => true,
    },
  )
  useEffect(() => {
    if (lastJsonMessage !== null) {
      setMessage(lastJsonMessage);
      setShowMessage(true);
      setTimeout(() => {
        setShowMessage(false);
      }, 2300);
    }
  }, [lastJsonMessage]);

  return (
    <>
    <Container>
      { message.url !== "" ? (
        <>
          <CSSTransition
            in={showMessage}
            timeout={3000}       // Длительность анимации в мс
            classNames='fade'   // Префикс классов анимации
            unmountOnExit       // Размонтировать элемент после анимации исчезновения
          >
            <div>
              <div id="notice" className='mt-auto'>
                <img src={message.gifUrl} alt="golos"/>
                <h2>{message.user}</h2>
                <p>{message.text}</p>
              </div>
              <audio id="myAudio" autoPlay={true} src={message.url}></audio>
            </div>
          </CSSTransition>
        </>
      ) : (
        <></>
      )}
    </Container>
    </>
  )
};

export default Voice;