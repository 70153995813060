import React from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { Container, Spinner } from "react-bootstrap";

import apiUrls from "../store/api.const";

function useQuery() {
    const { search } = useLocation();
  
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const TwitchConnect = () => {
    const navigate = useNavigate();
    let query = useQuery();
    let code = '?code=' + query.get("code");

    const [cookies, setCookie] = useCookies();
    const [token, setToken] = useState("");
    const [authenticated, setauthenticated] = useState(localStorage.getItem(localStorage.getItem("authenticated")|| false));
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Token ' + cookies.token }
    };
    fetch(apiUrls.connectToTwitch + code, requestOptions)
        .then(response => {
            response.json()
        })
        .then(data => {
            localStorage.setItem("authenticated", true);
            navigate('/settings');
        });

  return (
    <>
    <Container className="text-center mt-3">
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Connecting...</span>
        </Spinner>
      <span>Connecting...</span>
    </Container>
    </>
  );
};

export default TwitchConnect;