import React from "react";
import { useEffect, useState } from "react";
import {Button, Container, Spinner} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { connectTwitchUrl } from "../store/api.const";

const Home = () => {
  const navigate = useNavigate();
  const [authenticated, setauthenticated] = useState(localStorage.getItem(localStorage.getItem("authenticated")|| false));
  const loggedInUser = localStorage.getItem("authenticated");
  useEffect(() => {
    if (loggedInUser) {
        setauthenticated(loggedInUser);
        navigate("/settings");
    }
  }, []);

  return (
    <>
    <Container  fluid="true" className="text-center mt-3">
    {!authenticated ? (
        <>
        <p>Please login with Twitch. </p>
        <Button
          variant="dark"
          type="submit"
          href={connectTwitchUrl}
        >
          Login with Twitch
        </Button>
        </>
    ) : (
        <>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Redirecting to settings...</span>
        </Spinner>
        <span >Redirecting to settings...</span>
        </>
    )}
    </Container>
    </>
    )
};

export default Home;